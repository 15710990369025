body {
  font-family: Open Sans;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.banner {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
  padding: 0 6px;
  border-radius: 2px;
}

.bannerWrapper {
  background-color: #1f2a38;
  padding: 12px 8px;
  letter-spacing: 0px;
}

.bannerWrapper a {
  color: #fff;
}

#tabbar-scroller {
  scrollbar-color: rgba(11, 20, 27, 0.8) rgb(67, 87, 100);
  overflow: scroll;
}

#tabbar-scroller::-webkit-scrollbar {
  height: 8px;
  border-radius: 4px;
}
#tabbar-scroller::-webkit-scrollbar-thumb {
  background: rgb(67, 87, 100);
}
#tabbar-scroller::-webkit-scrollbar-track {
  background: rgba(11, 20, 27, 0.8);
  border: 1px solid rgb(14, 25, 35);
}
